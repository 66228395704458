<template>
  <a-pagination
    v-model="page.pageNum"
    :total="page.total"
    :pageSize="page.pageSize"
    :pageSizeOptions="page.pageSizeOptions"
    :showTotal="showTotal"
    :showQuickJumper="page.showQuickJumper"
    :showSizeChanger="page.showSizeChanger"
    @change="handleChange"
    @showSizeChange="handleChange"
  />
</template>
<script>
const I18N_KEY = 'components.'

export default {
  props: {
    total: {
      type: Number,
      default: 0,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    pageNum: {
      type: Number,
      default: 1,
    },
    pageSizeOptions: {
      type: Array,
      default: () => ['10', '50', '100']
    },
    showQuickJumper: {
      type: Boolean,
      default: true,
    },
    showSizeChanger: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    page() {
      return {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        total: this.total,
        pageSizeOptions: this.pageSizeOptions,
        showQuickJumper: this.showQuickJumper,
        showSizeChanger: this.showSizeChanger,
      }
    },
  },
  watch: {
    pageNum(val) {
      this.update('pageNum', val)
    },
    pageSize(val) {
      this.update('pageSize', val)
    },
  },
  methods: {
    showTotal(total, range) {
      return range[0] + '-' + range[1] + ` ${this.$t(I18N_KEY + 'total')} ` + total + ` ${this.$t(I18N_KEY + 'items')}`
    },
    handleChange(pageNum, pageSize) {
      this.update('pageNum', pageNum)
      this.update('pageSize', pageSize)
      this.$emit('change', pageNum, pageSize)
    },
    update(key, value) {
      this.$emit(`update:${key}`, value)
    },
  },
}
</script>
