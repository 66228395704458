import { axios } from '@/utils/request'

export function getBillList(parameter = {}) {
    return axios({
        url: '/bill/getBillPage',
        method: 'post',
        data: parameter,
    })
}

export function createBill(parameter = {}) {
    return axios({
        url: '/bill/saveBankBIllInfo',
        method: 'post',
        data: parameter,
    })
}

export function downloadTemplate(parameter = {}) {
    return axios({
        url: '/bill/downloadTemplate',
        method: 'post',
        data: parameter,
        maxBodyLength: Infinity,
        responseType: 'blob',
    })
}

//
export function matchSettle(parameter = {}) {
    return axios({
        url: '/bill/matchSettle',
        method: 'post',
        data: parameter,
    })
}

export function delBankBillInfo(parameter = {}) {
    return axios({
        url: '/bill/delBankBillInfo',
        method: 'post',
        data: parameter,
    })
}
export function uploadExcel(parameter = {}) {
    return axios({
        url: '/bill/uploadExcel',
        method: 'post',
        data: parameter,
    })
}

export function exportSettleList(parameter = {}) {
  return axios({
    url: '/settle/exportSettleDetail',
    method: 'post',
    data: parameter,
    maxBodyLength: Infinity,
    responseType: 'blob',
  })
}

export function preSettleBill(parameter = {}) {
  return axios({
    url: '/settle/preSettleBill',
    method: 'post',
    data: parameter,
  })
}

export function delSettleBill(parameter = {}) {
  return axios({
    url: '/settle/delSettleBill',
    method: 'post',
    data: parameter,
  })
}
export function getSettleBillPage(parameter = {}) {
  return axios({
    url: '/settle/getSettleBillPage',
    method: 'post',
    data: parameter,
  })
}

export function settleBill(parameter = {}) {
  return axios({
    url: '/settle/settleBill',
    method: 'post',
    data: parameter,
  })
}
