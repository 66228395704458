<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="inquire" :md="14" :sm="16" style="margin-bottom: 30px">
        <a-row :gutter="12">
          <a-col :md="7" :sm="8">
            <a-form-item label="商户编号" :labelCol="{ span: 6 }" :wrapperCol="{ span: 14, offset: 1 }">
              <a-input placeholder="请输入商户编号" v-model="queryParam.userNo"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="8" style="float: right">
            <a-button type="primary" @click="() => {queryParam.pageNum = 1; inquire()}"> 查询 </a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div>
      <a-table
        :pagination="pagination"
        rowKey="id"
        :columns="columns"
        :loading="loading"
        :dataSource="dataSource"
        bordered
      >
        <span slot="action" slot-scope="text, record">
          <a @click="detail(record)">
            <a-icon type="edit" />
            明细
          </a>
        </span>
        <span slot="accountType" slot-scope="text, record">
          {{ getAccountTypeValue(record.accountType) }}
        </span>
      </a-table>
    </div>
    <div class="modal" v-if="visible">
      <a-modal
        class="table-page-search-wrapper"
        width="80%"
        title="账户流水"
        :visible="visible"
        @cancel="handleCancel"
        destroyOnClose
        :footer="null"
      >
        <a-form layout="inline" @keyup.enter.native="inquire" :md="14" :sm="16" style="margin-bottom: 30px">
          <a-row :gutter="24">
            <a-col :md="10" :sm="8">
              开始时间：<a-date-picker
                show-time
                :default-value="moment(moment().subtract(7, 'days'), time.startDate)"
                v-model="time.startDate"
                :disabled-date="disabledStartDate"
                format="YYYY-MM-DD HH:mm:ss"
                placeholder="开始时间"
                @openChange="handleStartOpenChange"
              />
              结束时间：<a-date-picker
                :default-value="moment(moment().add(7, 'days'), time.endDate)"
                v-model="time.endDate"
                :disabled-date="disabledEndDate"
                show-time
                format="YYYY-MM-DD HH:mm:ss"
                placeholder="结束时间"
                :open="endOpen"
                @openChange="handleEndOpenChange"
              />

            </a-col>

            <a-col :md="4" :sm="8">
              <a-form-item label="金额类型" :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }">
                <a-select style="width: 120px" allowClear v-model="queryParam.amountType">
                  <a-select-option v-for="item in amountTypeArr"  :key="item.itemText" :value="item.itemValue">
                    {{ item.itemText }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="6" :sm="8">
              <a-form-item label="订单号" :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }">
                <a-input placeholder="订单号" v-model="queryParam.orderNo" style="width: 220px"></a-input>
              </a-form-item>
            </a-col>

            <a-col :md="2" :sm="8" style="float: right">
              <a-button type="primary" @click="reset"> 重置 </a-button>
            </a-col>


            <a-col :md="2" :sm="8" style="float: right">
              <a-button type="primary" @click="listinquire"> 查询 </a-button>
            </a-col>

          </a-row>
        </a-form>

        <a-table
          :pagination="paginationlist"
          rowKey="id"
          :columns="listcolumns"
          :data-source="listSource"
          bordered
          :loading="loading"
        >
          <span slot="accountTypeSolt" slot-scope="text, record">
            {{ getAccountTypeValue(record.accountType) }}
          </span>
          <span slot="amountType" slot-scope="text, record">
            {{ getAmountTypeValue(record.amountType) }}
          </span>
          <span slot="cashFrom" slot-scope="text, record">
            {{ getCashFromValue(record.cashFrom) }}
          </span>
        </a-table>
      </a-modal>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import { GetUserList, GetUserListflow } from '@/api/user.js'
import { findDictItemByCode } from '@/api/pay.js'
// 商户信息
const columns = [
  {
    title: '创建日期',
    dataIndex: 'createTime',
  },
  {
    title: '商户编号',
    dataIndex: 'userNo',
  },
  {
    title: '账户类型',
    dataIndex: 'accountType',
    scopedSlots: { customRender: 'accountType' },
  },
  {
    title: '余额',
    dataIndex: 'balance',
  },
  {
    title: '冻结金额',
    dataIndex: 'freezeAmount',
  },
  {
    title: '可用余额',
    dataIndex: 'availableAmount',
  },

  {
    title: '操作',
    dataIndex: 'action',
    align: 'center',
    scopedSlots: { customRender: 'action' },
  },
]
// 商户信息明细
const listcolumns = [
  {
    title: '日期',
    dataIndex: 'createTime',
  },
  {
    title: '平台订单号  ',
    dataIndex: 'orderNo',
  },
  {
    title: '金额类型',
    dataIndex: 'amountType',
    scopedSlots: { customRender: 'amountType' },
  },
  {
    title: '交易金额',
    dataIndex: 'amount',
  },
  {
    title: '期初金额',
    dataIndex: 'beginBalance',
  },
  {
    title: '期末金额',
    dataIndex: 'endBalance',
  },
]
let accountType = []
let amountTypeArr = []
let cashFromArr = []
let amountType = []
let selectRow = null

export default {
  name: 'merchantInfo',
  data() {
    return {
      moment,
      // 弹窗
      visible: false,
      // 商户信息/流水传参
      queryParam: {
        userNo: '',
        pageNum: 1,
        pageSize: 10,
        accountType: null,
        accountId: null,
        orderNo: null,
        amountType: null
      },
      // 日期
      time: {
        startDate: '开始日期',
        endDate: '结束日期',
      },
      columns,
      listcolumns,
      // 商户信息
      dataSource: [],
      amountTypeArr: [],
      // 商户明细详情
      listSource: [],
      // 账户类型
      endOpen: false,
      loading: false,
      // 分页配置
      pagination: {
        pageSize: 0, // 每页显示的条数
        showSizeChanger: true, // 是否可以改变每页显示的条数
        pageSizeOptions: ['5', '10', '15', '20'], // 可选的每页显示条数
        showQuickJumper: true, // 是否可以快速跳转到指定页
        showTotal: 0, // 显示总条数和当前数据范围
        current: 0, // 当前页数
        total: 0, // 总条数
        onChange: this.handlePageChange, // 页码改变时的回调函数
      },
      paginationlist: {
        pageSize: 0, // 每页显示的条数
        showSizeChanger: true, // 是否可以改变每页显示的条数
        pageSizeOptions: ['5', '10', '15', '20'], // 可选的每页显示条数
        showQuickJumper: true, // 是否可以快速跳转到指定页
        showTotal: 0, // 显示总条数和当前数据范围
        current: 0, // 当前页数
        total: 0, // 总条数
        onChange: this.handlePageChangelist, // 页码改变时的回调函数
      },
    }
  },
  watch: {
    startDate(val) {
      console.log('startDate', val)
    },
    endDate(val) {
      console.log('endDate', val)
    },
  },
  created() {
    this.time = {
      startDate: moment().subtract(7, 'days'),
      endDate: moment().add(7, 'days'),
    }
    findDictItemByCode('accountType,amountType').then((res) => {
      if (res.code == '2000') {
        accountType = res.data.accounttype
        amountTypeArr = res.data.amounttype
        this.amountTypeArr=res.data.amounttype
      }
    })
    findDictItemByCode('cashFrom').then((res) => {
      if (res.code == '2000') {
        cashFromArr = res.data.cashfrom
      }
    })
    findDictItemByCode('amountType').then((res) => {
      if (res.code == '2000') {
        amountType = res.data.amounttype
      }
    })
  },
  methods: {
    // 获取商户信息
    getAccountTypeValue(val) {
      console.log(accountType)
      const type = accountType.find((item) => item.itemValue == val)
      return type ? type.itemText : ''
    },
    getCashFromValue(val) {
      const type = cashFromArr.find((item) => item.itemValue == val)
      return type ? type.itemText : ''
    },
    getAmountTypeValue(val) {
      const type = amountType.find((item) => item.itemValue == val)
      return type ? type.itemText : ''
    },
    inquire() {
      this.loading = true
      ;(this.queryParam.startDate = undefined), (this.queryParam.endDate = undefined)
      GetUserList(this.queryParam).then((res) => {
        this.dataSource = res.data.list
        this.$message.info(res.msg)
        this.pagination = {
          pageSize: res.data.page.pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '15', '20'],
          showQuickJumper: true,
          showTotal: (total) => `共 ${total} 条`,
          current: res.data.page.pageNum,
          total: res.data.page.total,
          onChange: this.handlePageChange,
        }
      })
      this.loading = false
      selectRow = null
    },
    // 获取商户明细流水
    listinquire() {
      this.loading = true
      this.data = { ...this.queryParam, ...this.time }
      if (selectRow) {
        this.data.accountType = selectRow.accountType
        this.data.userNo = selectRow.userNo
        this.data.accountId = selectRow.accountId
      }
      GetUserListflow(this.data).then((res) => {
        this.listSource = res.data.list
        this.$message.info(res.msg)
        this.paginationlist = {
          pageSize: res.data.page.pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '15', '20'],
          showQuickJumper: true,
          showTotal: (total) => `共 ${total} 条`,
          current: this.queryParam.pageNum,
          total: res.data.page.total,
          onChange: this.handlePageChangelist,
        }
      })
      this.loading = false
    },
    // 查看明细
    detail(record) {
      this.visible = true
      selectRow = record
    },

    // handleOk(e) {
    //   setTimeout(() => {
    //     this.visible = false
    //   }, 2000)
    // },
    handleCancel(e) {
      this.paginationlist = false
      this.visible = false
      console.log('关闭')
      this.listSource = []
    },

    // 选择日期时间
    disabledStartDate(startDate) {
      const endDate = this.time.endDate
      if (!startDate || !endDate) {
        return false
      }
      return startDate.valueOf() > endDate.valueOf()
    },
    disabledEndDate(endDate) {
      const startDate = this.time.startDate
      if (!endDate || !startDate) {
        return false
      }
      return startDate.valueOf() >= endDate.valueOf()
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open
    },

    // 重置时间
    reset() {
      this.$message.info('重置成功')
      console.log('重置')
      this.time = {
        startDate: null,
        endDate: null,
      }
      this.queryParam.pageNum = 1
      this.queryParam.pageSize = 10
      this.queryParam.amountType=undefined
      this.listSource = []
      this.paginationlist = {}
    },
    // 分页
    handlePageChange(page, pageSize) {
      this.queryParam.pageNum = page
      this.queryParam.pageSize = pageSize
      this.inquire()
    },
    handlePageChangelist(page, pageSize) {
      this.queryParam.pageNum = page
      this.queryParam.pageSize = pageSize
      this.listinquire()
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/common.less';

th.column-money,
td.column-money {
  text-align: right !important;
}
</style>
