<template>
  <div>
    <div style="background-color: #f0f2f5; padding: 10px;">
      <a-row :gutter="16">
        <a-col :span="8">
          <a-card title="代付账户可用余额" :bordered="false">
            <p style="font-size: 16px; font-weight: 500">{{ formatPrice(user.balance || 0) }}</p>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card title="代付账户在途余额" :bordered="false">
            <p style="font-size: 16px; font-weight: 500">{{ formatPrice(user.freezeAmount || 0) }}</p>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card title="代收账户余额" :bordered="false">
            <p style="font-size: 16px; font-weight: 500">{{ formatPrice(user.transitAmount || 0) }}</p>
          </a-card>
        </a-col>
      </a-row>
    </div>

    <div style="background-color: #f0f2f5; padding: 10px;">
      <a-row :gutter="16">
        <a-col :span="4">
          <a-card title="今日代付金额" :bordered="false">
            <p style="font-size: 16px; font-weight: 500">{{ formatPrice(user.todayPayOutAmount || 0) }}</p>
          </a-card>
        </a-col>
        <a-col :span="4">
          <a-card title="今日代付手续费" :bordered="false">
            <p style="font-size: 16px; font-weight: 500">{{ formatPrice(user.todayPayOutFee || 0) }}</p>
          </a-card>
        </a-col>
        <a-col :span="4">
          <a-card title="今日代付笔数" :bordered="false">
            <p style="font-size: 16px; font-weight: 500;">{{ formatPrice(user.todayPayOutCount || 0) }}</p>
          </a-card>
        </a-col>

        <a-col :span="4">
          <a-card title="今日代收金额" :bordered="false">
            <p style="font-size: 16px; font-weight: 500">{{ formatPrice(user.todayPayInAmount || 0) }}</p>
          </a-card>
        </a-col>
        <a-col :span="4">
          <a-card title="今日代收手续费" :bordered="false">
            <p style="font-size: 16px; font-weight: 500">{{ formatPrice(user.todayPayInFee || 0) }}</p>
          </a-card>
        </a-col>
        <a-col :span="4">
          <a-card title="今日代收笔数" :bordered="false">
            <p style="font-size: 16px; font-weight: 500;">{{ formatPrice(user.todayPayInCount || 0) }}</p>
          </a-card>
        </a-col>
      </a-row>
    </div>

    <div style="background-color: #f0f2f5; padding: 10px;">
      <a-row :gutter="16">
        <a-col :span="4">
          <a-card title="总代付金额" :bordered="false">
            <p style="font-size: 16px; font-weight: 500">{{ formatPrice(user.allPayOutAmount || 0) }}</p>
          </a-card>
        </a-col>
        <a-col :span="4">
          <a-card title="总代付手续费" :bordered="false">
            <p style="font-size: 16px; font-weight: 500">{{ formatPrice(user.allPayOutFee || 0) }}</p>
          </a-card>
        </a-col>
        <a-col :span="4">
          <a-card title="总代付笔数" :bordered="false">
            <p style="font-size: 16px; font-weight: 500">{{ formatPrice(user.allPayOutCount || 0) }}</p>
          </a-card>
        </a-col>

        <a-col :span="4">
          <a-card title="总代收金额" :bordered="false">
            <p style="font-size: 16px; font-weight: 500">{{ formatPrice(user.allPayInAmount || 0) }}</p>
          </a-card>
        </a-col>
        <a-col :span="4">
          <a-card title="总代收手续费" :bordered="false">
            <p style="font-size: 16px; font-weight: 500">{{ formatPrice(user.allPayInFee || 0) }}</p>
          </a-card>
        </a-col>
        <a-col :span="4">
          <a-card title="总代收笔数" :bordered="false">
            <p style="font-size: 16px; font-weight: 500">{{ formatPrice(user.allPayInCount || 0) }}</p>
          </a-card>
        </a-col>

      </a-row>
    </div>

    <div style="background-color: #f0f2f5; padding: 10px;">
      <a-row :gutter="16">
        <a-col :span="4">
          <a-card title="今日提现金额" :bordered="false">
            <p style="font-size: 16px; font-weight: 500">{{ formatPrice(user.todayWithdrawAmount || 0) }}</p>
          </a-card>
        </a-col>
        <a-col :span="4">
          <a-card title="今日充值金额" :bordered="false">
            <p style="font-size: 16px; font-weight: 500">{{ formatPrice(user.todayChargeAmount || 0) }}</p>
          </a-card>
        </a-col>
        <a-col :span="4">
          <a-card title="今日结算金额" :bordered="false">
            <p style="font-size: 16px; font-weight: 500">{{ formatPrice(user.todaySettleAmount || 0) }}</p>
          </a-card>
        </a-col>

        <a-col :span="4">
          <a-card title="总提现金额" :bordered="false">
            <p style="font-size: 16px; font-weight: 500">{{ formatPrice(user.allWithdrawAmount || 0) }}</p>
          </a-card>
        </a-col>
        <a-col :span="4">
          <a-card title="总充值金额" :bordered="false">
            <p style="font-size: 16px; font-weight: 500">{{ formatPrice(user.allChargeAmount || 0) }}</p>
          </a-card>
        </a-col>
        <a-col :span="4">
          <a-card title="总结算金额" :bordered="false">
            <p style="font-size: 16px; font-weight: 500">{{ formatPrice(user.allSettleAmount || 0) }}</p>
          </a-card>
        </a-col>

      </a-row>
    </div>


    <div>
      <!-- <div class="head">
        <div class="balance">代付账户可用余额: {{ formatPrice(user.balance)  }}</div>
        <div class="freezeAmount">代付账户在途余额: {{ formatPrice(user.freezeAmount) }}</div>
        <div class="transitAmount">代收账户余额: {{ formatPrice(user.transitAmount) }}</div>
      </div> -->
      <div>
        <a-form layout="inline" @keyup.enter.native="inquire" style="margin-bottom: 30px">
          <a-row>
            <a-col :span="8">
              <a-form-item label="完成时间" :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }">
                <!-- <a-date-picker v-model="queryParam.beginTime" style="width: 200px" /> -->
                <a-range-picker allowClear v-model="queryParam.createTime" style="width: 240px" @change="changeSelectDate">
                </a-range-picker>
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-form-item label="金额类型" :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }">
                <a-select style="width: 120px" allowClear v-model="queryParam.amountType">
                  <a-select-option v-for="item in amountTypeArr"  :key="item.itemText" :value="item.itemValue">
                    {{ item.itemText }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="订单号" :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }">
                <a-input placeholder="订单号" v-model="queryParam.orderNo" style="width: 220px"></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="5" :offset="1">
              <a-button
                type="primary"
                @click="
                  () => {
                    queryParam.pageNum = 1
                    searchQuery()
                  }
                "
                style="margin-right: 20px"
              >
                查询
              </a-button>
              <a-button type="primary" @click="reset"> 重置</a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div>
        <a-table
          ref="table"
          rowKey="id"
          size="middle"
          :loading="loading"
          :columns="columns"
          :dataSource="dataSource"
          :pagination="false"
          bordered
        >
          <template slot="beginBalance" slot-scope="text">
            <span>{{ formatPrice(text) }}</span>
          </template>
          <template slot="endBalance" slot-scope="text">
            <span>{{ formatPrice(text) }}</span>
          </template>
          <template slot="amount" slot-scope="text">
            <span>{{ formatPrice(text) }}</span>
          </template>
          <span slot="accountType" slot-scope="text, record">
            {{ getAccountTypeValue(record.accountType) }}
          </span>
          <span slot="amountType" slot-scope="text, record">
            {{ getAmountTypeValue(record.amountType) }}
          </span>
        </a-table>
        <div style="margin-top: 15px; text-align: right">
          <v-pagination
            v-if="total"
            :page-num="queryParam.pageNum"
            :page-size="queryParam.pageSize"
            :total="total"
            @change="handleTableChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { GetUser, GetUserListflow } from '../../../api/user.js'
import { findDictItemByCode } from '@/api/pay.js'
// 商户信息
const columns = [
  {
    title: '完成时间',
    dataIndex: 'createTime',
    align: 'center'
  },
  {
    title: '平台订单号  ',
    dataIndex: 'orderNo',
    align: 'center'
  },
  {
    title: '金额类型',
    dataIndex: 'amountType',
    align: 'center',
    scopedSlots: { customRender: 'amountType' }
  },
  {
    title: '交易金额',
    dataIndex: 'amount',
    align: 'right',
    scopedSlots: { customRender: 'amount' }
  },
  {
    title: '期初金额',
    dataIndex: 'beginBalance',
    align: 'right',
    scopedSlots: { customRender: 'beginBalance' }
  },
  {
    title: '期末金额',
    dataIndex: 'endBalance',
    align: 'right',
    scopedSlots: { customRender: 'endBalance' }
  }
]
let accountType = []
let amountTypeArr = []

export default {
  data() {
    return {
      moment,
      // 加载
      loading: false,
      // table表格
      columns,
      data: [],
      dataSource: [],
      amountTypeArr: [],
      // 日期
      time: {
        startDate: null,
        endDate: null
      },
      total: 0,
      endOpen: false,
      // 账户信息
      userId: null,
      user: {
        balance: null, // 账户余额
        freezeAmount: null, // 冻结金额
        transitAmount: null // 在途金额
      },
      // 账户流水
      queryParam: {
        userNo: this.$store.state.user.userNo,
        userId: this.$store.state.user.userId,
        startDate: '开始日期',
        endDate: '结束日期',
        createTime: '',
        accountType: undefined,
        amountType: undefined,
        pageNum: 1,
        pageSize: 10
      }
    }
  },
  created() {
    this.queryParam.createTime = [moment().subtract(7, 'days'), moment().add(0, 'days')]
    this.usersms(this.queryParam.userId)

    findDictItemByCode('accountType,amountType').then((res) => {
      if (res.code == '2000') {
        accountType = res.data.accounttype
        amountTypeArr = res.data.amounttype
        this.amountTypeArr=res.data.amounttype
        setTimeout(() => {
          this.searchQuery()
        }, 500)
      }
    })
  },
  watch: {
    startValue(val) {
      console.log('startValue', val)
    },
    endValue(val) {
      console.log('endValue', val)
    }
  },
  methods: {
    changeSelectDate(date) {
      if (date.length === 0) {
        this.queryParam.createTime = null
      }
    },
    getAccountTypeValue(val) {
      console.log(accountType)
      const type = accountType.find((item) => item.itemValue == val)
      return type ? type.itemText : ''
    },
    getAmountTypeValue(val) {
      const type = amountTypeArr.find((item) => item.itemValue == val)
      return type ? type.itemText : '-'
    },
    formatPrice(price) {
      return String(price).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    // 查询
    searchQuery() {
      const params = { ...this.queryParam }
      const { createTime } = params
      if (createTime) {
        params.startDate = `${moment(createTime[0]).format('YYYY-MM-DD')} 00:00:00`
        params.endDate = `${moment(createTime[1]).format('YYYY-MM-DD')} 23:59:59`
        delete params.createTime
      } else {
        params.startDate = undefined
        params.endDate = undefined
      }

      GetUserListflow(params).then((res) => {
        this.dataSource = res.data.list
        this.total = res.data.total
        this.loading = false
      })
    },
    // 重置
    reset() {
      this.queryParam.orderNo = undefined
      this.accountType= undefined,
      this.amountType= undefined,
      this.queryParam.startDate = undefined
      this.queryParam.endDate = undefined
      this.queryParam.createTime = undefined
      this.queryParam.pageNum = 1
      this.queryParam.pageSize = 10
      this.data = []
    },
    handleTableChange(pageNum, pageSize) {
      this.queryParam.pageNum = pageNum
      this.queryParam.pageSize = pageSize
      this.searchQuery()
    },
    // 可用/冻结/在途余额
    usersms(val) {
      this.userId = new FormData()
      this.userId.append('userId', val)
      GetUser(this.userId).then((res) => {
        this.user = {
          balance: res.data.availableAmount, // 账户余额
          freezeAmount: res.data.freezeAmount, // 冻结金额
          transitAmount: res.data.transitAmount, // 在途金额

          todayPayInAmount: res.data.todayPayInAmount,
          todayPayInCount: res.data.todayPayInCount,
          todayPayInFee: res.data.todayPayInFee,
          todayPayOutAmount: res.data.todayPayOutAmount,
          todayPayOutCount: res.data.todayPayOutCount,
          todayPayOutFee: res.data.todayPayOutFee,
          todaySettleAmount: res.data.todaySettleAmount,
          todaySettleCount: res.data.todaySettleCount,
          todayChargeAmount: res.data.todayChargeAmount,
          todayWithdrawAmount: res.data.todayWithdrawAmount,

          allPayInAmount: res.data.allPayInAmount,
          allPayInCount: res.data.allPayInCount,
          allPayInFee: res.data.allPayInFee,
          allPayOutAmount: res.data.allPayOutAmount,
          allPayOutCount: res.data.allPayOutCount,
          allPayOutFee: res.data.allPayOutFee,
          allSettleAmount: res.data.allSettleAmount,
          allSettleCount: res.data.allSettleCount,
          allChargeAmount: res.data.allChargeAmount,
          allWithdrawAmount: res.data.allWithdrawAmount

        }
      })
    }
  }
}
</script>

<style lang="less">
/*  background: #f0f2f5 url(~@/assets/background.svg) no-repeat 50%; */
.amount {
  text-align: center;
  margin: 20px;
  background-color: #fff;
  padding: 30px;
  box-shadow: 0px 1px 5px #ccc;
}

.title {
  font-size: 20px;
}

th.column-money,
td.column-money {
  text-align: right !important;
}

.head {
  position: relative;
  width: 578px;
  height: 318px;
  background-image: url('../../../assets/bubble.png');
  background-size: cover;
  margin: 0 auto;
  transform: scale(0.8);
  color: #1783fc;
  font-size: 18px;
  font-weight: bold;

  .balance {
    position: relative;
    top: 25px;
    right: 20px;
    text-align: right;
  }

  .freezeAmount {
    position: relative;
    top: 70px;
    right: 40px;
    text-align: right;
  }

  .transitAmount {
    position: relative;
    top: 125px;
    right: 50px;
    text-align: right;
  }
}
</style>
