<template>
    <div>
      <a-col layout="inline" @keyup.enter.native="searchQuery" style="margin-bottom: 30px">
        <a-row dispaly="flex" justify="space-around">
          <a-col :span="6">
            <a-form-item label="创建时间" :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }">
              <a-range-picker v-model="queryParams.createTime" style="width: 230px" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="商户" :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }">
              <a-select
                ref="select"
                style="width: 180px"
                @change="onUserNameChange"
                v-decorator="['userNo']"
              >
                <a-select-option v-for="item in merchants" :value="item.userNo" :key="item.userNo">
                  {{ item.userName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="结算状态" :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }">
              <a-select style="width: 150px" v-model="queryParams.settleStatus" allowClear>
                <a-select-option v-for="(item, index) in settleStatusArr" :key="index" :value="item.itemValue">{{ item.itemText }} </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="2">
            <span style="float: left; overflow: hidden" class="table-page-search-submitButtons">
              <a-button type="primary" icon="plus" @click="() => {queryParams.pageNum = 1; searchQuery()}">查询</a-button>
            </span>
          </a-col>
        </a-row>
      </a-col>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :loading="loading"
        :scroll="{ x: 1000 }"
        :pagination="false"
        bordered
        :row-selection="rowSelection"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ moment(record.createTime).format('YYYY-MM-DD HH:mm:SS') }}
        </span>
        <span slot="settleStatus" slot-scope="text, record">
          {{ record.settleStatus == '0' ? '结算中' : (record.settleStatus == '1' ? '未结算' : '已结算') }}
        </span>
        <span slot="action" slot-scope="text, record">
          <a-button type="primary" @click="handleEdit(record)" :disabled="record.settleStatus == '2'">结算</a-button>
        </span>
      </a-table>
      <div style="margin-top: 15px; text-align: right">
        <v-pagination
          v-if="total"
          :page-num="queryParams.pageNum"
          :page-size="queryParams.pageSize"
          :total="total"
          @change="handleTableChange"
        />
      </div>
    </div>
  </template>
  <script>
  import { mapGetters } from 'vuex'
  import { getAllChannels } from '@/api/channel'
  import { activeSettle, findDictItemByCode, getMerchantPage, uploadSettleExcel } from '@/api/pay'
  import ATip from 'ant-design-vue/es/tooltip'
  import moment from 'moment'
  import { message } from 'ant-design-vue'
  import { getSettleBillPage, settleBill } from '@/api/settle'

  export default {
    name: 'SettleActive',
    components: {
      ATip,
    },
    data() {
      return {
        moment,
        visible: false,
        transtypeArr: [],
        settleStatusArr: [],
        fileList: [],
        selectedRowKeys: [],
        queryParams: {
          userNo: undefined,
          settleStatus: undefined,
          beginCreateTime: '', //开始时间
          endCreateTime: '', //结束时间
          createTime: undefined,
          pageNum: 1,
          pageSize: 10
        },
        merchants: [],
        settlecomparisonresut: [], //异常类型
        dataSource: [],
        channels: [],
        // 异常类型
        exceptions: [],
        loading: false,
        total: 0,
        columns: [
          {
            title: '对账单名称',
            dataIndex: 'billFileName'
          },
          {
            title: '商户号',
            dataIndex: 'userNo'
          },

          {
            title: '结算金额',
            dataIndex: 'settleAmount'
          },
          {
            title: '结算笔数',
            dataIndex: 'settleCount'
          },
          {
            title: '结算状态',
            dataIndex: 'settleStatus',
            scopedSlots: { customRender: 'settleStatus' }
          },
          {
            title: '结算时间',
            dataIndex: 'settleTime'
          },
          {
            title: '创建时间',
            dataIndex: 'createTime'
          },
          {
            title: '创建人',
            dataIndex: 'creator'
          },
          {
            title: '操作',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' }
          }
        ],
        confirmLoading: false,
        userName: localStorage.getItem('userName') || ''
      }
    },
    computed: {
      ...mapGetters(['userNo', 'userId']),
      rowSelection() {
        return {
          onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            this.selectedRowKeys = selectedRows.map(item => {
              return item.id
            })
            console.log(this.selectedRowKey)
          },
          getCheckboxProps: record => ({
            props: {
              disabled: record.settleStatus == '2', // Column configuration not to be checked
              settleStatus: record.settleStatus,
            },
          }),
        };
      },
    },
    created() {
      findDictItemByCode('settleComparisonResut').then((res) => {
        this.settlecomparisonresut = res.data.settlecomparisonresut
      })
      findDictItemByCode('transType').then((res) => {
        this.transtypeArr = res.data.transtype
      })
      findDictItemByCode('settleStatus').then((res) => {
        this.settleStatusArr = res.data.settlestatus
        this.settleStatusArr = this.settleStatusArr.filter(item => item.itemValue !== "1")
      })
      getMerchantPage({ pageNum: 1, pageSize: 999 }).then((res) => {
        this.merchants = res.data.list
      })
      this.init()
    },
    methods: {
      onUserNameChange(userNo) {
        this.queryParams.userNo = userNo
      },
      handleOk() {
        uploadSettleExcel({file: this.fileList[0]}).then((res) => {
          console.log(res)
          if (res.code === '2000') {
            message.success('上传成功')
            this.visible = false
            this.searchQuery()
          } else {
            message.error(res.msg)
          }
        })
      },
      handleChange(){
        console.log(this.selectedRowKeys, 'selectedRowKeys') // 选中的role
      },
      settleAll() {
        this.loading = true
        activeSettle([...this.selectedRowKeys]).then((res) => {
          if (res.code === '2000') {
            message.success('结算成功')
            this.searchQuery()
          } else {
            message.error(res.msg)
          }
          this.loading = false
        })
        .catch(() => {
            this.loading = false
          })
      },
      handleEdit(record) {
        this.loading = true
        let data=new FormData()
        data.append("billSettleInfoId",record.id)
        settleBill(data).then((res) => {
          if (res.code === '2000') {
            message.success('结算成功')
          } else {
            message.error(res.msg)
          }
          this.searchQuery()
          this.loading = false
        }).catch(() => {
            this.loading = false
          })
      },
      init() {
        this.getChannels()
        this.searchQuery()
      },
      async getChannels() {
        const res = await getAllChannels()
        if (res.code === '2000') {
          this.channels = res.data
        }
      },
      handleTableChange(pageNum, pageSize) {
        this.queryParams.pageNum = pageNum
        this.queryParams.pageSize = pageSize
        this.searchQuery()
      },
      createRule() {
        this.modalvisible = true
      },
      searchQuery() {
        this.loading = true
        const params = { ...this.queryParams }
        const { createTime } = params
        if (createTime) {
          params.beginCreateTime = `${moment(createTime[0]).format('YYYY-MM-DD 00:00:00')}`
          params.endCreateTime = `${moment(createTime[1]).format('YYYY-MM-DD 23:59:59')}`
        }
        delete params.createTime

        getSettleBillPage(params).then((res) => {
          if (res.code != '2000') {
            message.error(res.msg)
          } else {
            this.dataSource = res.data.list
            this.total = res.data.total
            this.loading = false
          }
          this.selectedRowKeys = []
          this.loading = false
        })
      },
    },
  }
  </script>
  <style scoped lang="less">
  .appContainer ::v-deep {
    .ant-form-item {
      margin-bottom: 14px;
    }
  }
  
  /deep/ .ant-form-item {
    margin-bottom: 14px;
  }
  </style>
  